$(function() {
    let borderSlider = $('.js-configurator-border');
    let panelsSlider = $('.js-configurator-panels');

    borderSlider.on('init', function(event, slick, currentSlide, nextSlide){
        setBorderLbl();
        setPriceLbl();
    });
    borderSlider.on('init, afterChange', function(event, slick, currentSlide, nextSlide){
        setBorderLbl();
        setPriceLbl();
    });
    
    borderSlider.slick({
        infinite: true,
        dots: false,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
        prevArrow: '.js-configurator-pager-border-prev',
        nextArrow: '.js-configurator-pager-border-next',
    });


    panelsSlider.on('init', function(event, slick, currentSlide, nextSlide){
        setPanelLbl();
    });
    panelsSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        setPanelLbl();
    });

    panelsSlider.slick({
        infinite: true,
        dots: false,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
        prevArrow: '.js-configurator-pager-panels-prev',
        nextArrow: '.js-configurator-pager-panels-next',
    });

    
    function setBorderLbl(){
        let active = borderSlider.find(".slick-active");
        $('.js-configurator-border-lbl').text(active.find('img').data('name'));
    }
    function setPanelLbl(){
        let active = panelsSlider.find(".slick-active");
        $('.js-configurator-panels-lbl').text(active.find('img').data('name'));
    }
    function setPriceLbl(){
        let active = borderSlider.find(".slick-active");
        $('.js-configurator-price').text(active.find('img').data('price'));
    }

    function get_combinazione() {
        let border = borderSlider.find(".slick-active img").data('code');
        let panels = panelsSlider.find(".slick-active img").data('code');
        return border.toLowerCase() + '-' + panels.toLowerCase();
    }

    var data = {};
    $('.js-configurator-save').on('click', function(e) {
        data.combinazione = get_combinazione();

        var link = document.createElement('a');
        link.href = "./img/style/configurator/out/" + data.combinazione + ".png";

       
        link.download = data.combinazione + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    });


})