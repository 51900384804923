$(function() {
    $(document).on('click', '.js-header-mmenu-btn', function(){
        $('.js-header-mmenu').toggleClass('active');
        $('.js-header-menu-bg').toggleClass('active');
    });

    $(".js-header-scroll").on("click", function (event) {
		event.preventDefault();
        $('.js-header-mmenu').removeClass('active');
        $('.js-header-menu-bg').removeClass('active');
		var id  = $(this).attr('href'),
			top = $(id).offset().top;		
		$('body,html').animate({scrollTop: top}, 700);
	});

    $('.js-header-menu-bg').on('click', function(){
        $('.js-header-mmenu').removeClass('active');
        $('.js-header-menu-bg').removeClass('active');

    })
})