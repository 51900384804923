$(function() {
    $("[data-fancybox]").fancybox({
        autoFocus: false,
        touch: false,
    });
    var onChangeInput = function onChangeInput(evt) {
        if (evt.target.value !== '') {
            evt.target.classList.add('hasValue');
        } else {
            evt.target.classList.remove('hasValue');
        }
    };
    $('form').find('input').on('input', onChangeInput);
})