$(function() {
    let block = $('.js-scenari'),
        control = $('.js-scenari-control'),
        slider = control.closest('.js-scenari').find('.js-scenari-slider');

    control.eq(0).addClass('active');
    
    control.on('click', function(e){
        e.preventDefault();
        let i = $(this).attr('data-slide');
        slider.slick('slickGoTo', i - 1, false);
        control.removeClass('active');
        $(this).addClass('active');
    })
})